<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <div
          v-if="readOnly"
          class="alert alert-light alert-elevate"
          role="alert"
        >
          <div class="alert-text">
            <p>
              {{ $t("REPORTS.THIS_A_GLOBAL_REPORT") }}
            </p>
          </div>
        </div>

        <b-tabs content-class="mt-3" pills @activate-tab="tabChanged">
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  v-bind:disabled="readOnly"
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description"
              >
                <b-form-input
                  v-bind:disabled="readOnly"
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="user.grants.includes('SYSTEM:ADMIN')"
                id="field-global"
                label-cols-lg="2"
                :label="$t('PARAMETERS.GLOBAL')"
                label-for="field-global-input"
              >
                <b-form-checkbox
                  v-bind:disabled="readOnly"
                  id="checkbox-approved-input"
                  v-model="dto.global"
                  name="checkbox-global"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('REPORTS.PARTS')">
            <b-table
              id="table-parameters"
              striped
              bordered
              :items="dto.reportParts"
              :fields="reportPartFields"
              selectable
              select-mode="single"
              @row-selected="onPartRowSelected"
            >
            </b-table>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button
            variant="success"
            v-if="!readOnly"
            v-on:click="onSaveClicked"
            >{{ $t("COMMON.SAVE") }}</b-button
          >
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-add-part"
      :title="$t('REPORTS.NEW_REPORT_PART')"
      @ok="addNewPart"
      @cancel="cancelPart"
      size="xl"
    >
      <b-card-body>
        <b-form-group
          id="field-report-part-type"
          label-cols-lg="2"
          :label="$t('REPORTS.REPORT_PART_TYPE')"
          label-for="field-report-part-type"
        >
          <b-form-select
            v-model="newPart.reportPartTypeId"
            :options="reportPartTypes"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="field-report-part-sort-order"
          label-cols-lg="2"
          :label="$t('REPORTS.SORT_ORDER')"
          label-for="field-sort-order-input"
        >
          <b-form-input
            id="field-sort-order-input"
            v-model="newPart.sortOrder"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="field-report-part-annotation"
          label-cols-lg="2"
          :label="$t('REPORTS.ANNOTATION')"
          label-for="field-annotation-input"
        >
          <b-form-textarea
            id="field-annotation-input"
            v-model="newPart.annotation"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <!--DEVICES-->

        <b-form-group
          id="field-devices"
          label-cols-lg="2"
          :label="$t('MENU.DEVICES')"
          label-for="field-devices"
        >
          <multi-suggestion
            id="deviceSuggestion"
            suggestion-processor="deviceSuggestionProcessor"
            :element-name="$t('DEVICE.DEVICE')"
            :elements-name="$t('DEVICE.DEVICES')"
            :selected-elements="selectedDevices"
            :addErrorNotification="true"
            @on-element-added="onDeviceSelected"
            @on-remove-element="removeDevice"
          ></multi-suggestion>
        </b-form-group>

        <!--PARAMETERS-->

        <template
          v-if="newPart.reportPartTypeId == constants.REPORT_PART_TYPES.MEASURE"
        >
          <b-form-group
            id="field-view-type"
            label-cols-lg="2"
            :label="$t('REPORTS.VIEW_TYPE')"
            label-for="field-view-type"
          >
            <b-form-select v-model="newPart.viewTypeId" :options="viewTypes">
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("REPORTS.SELECT_VIEW_TYPE") }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="field-parameters"
            label-cols-lg="2"
            :label="$t('MENU.PARAMETERS')"
            label-for="field-parameters"
          >
            <multi-suggestion
              id="parameterSuggestion"
              suggestion-processor="parameterSuggestionProcessor"
              :element-name="$t('PARAMETERS.PARAMETER')"
              :elements-name="$t('PARAMETERS.PARAMETERS')"
              :selected-elements="selectedParameters"
              :addErrorNotification="true"
              @on-element-added="onParameterSelected"
              @on-remove-element="removeParameter"
            ></multi-suggestion>
          </b-form-group>
        </template>
        <template
          v-if="newPart.reportPartTypeId == constants.REPORT_PART_TYPES.MESSAGE"
        >
          <b-form-group
            id="field-messages-type"
            label-cols-lg="2"
            :label="$t('MESSAGES.MESSAGE_TYPE')"
            label-for="field-message-type"
            inline
          >
            <b-form-checkbox-group
              v-model="selectedMessageTypes"
              :options="messageTypes"
            ></b-form-checkbox-group>
          </b-form-group>
        </template>

        <!--MESSAGE TYPES-->
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Application from "../../application";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import MultiSuggestion from "../component/MultiSuggestion";

export default {
  name: "report",
  mixins: [SuggestionProcessorMixin],
  components: {
    MultiSuggestion,
  },
  data() {
    return {
      save: {
        resource: "/api/report/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        description: null,
        global: false,
        teamName: null,
        teamId: ApiService.teamData.value,
        reportParts: [],
      },

      reportPartFields: [
        {
          label: this.$t("REPORTS.PART_TYPE"),
          formatter: this.partFormatter,
          key: "reportPartTypeId",
        },
        { label: this.$t("REPORTS.SORT_ORDER"), key: "sortOrder" },
        {
          label: this.$t("REPORTS.VIEW"),
          formatter: this.viewFormatter,
          key: "viewTypeId",
        },
        { label: this.$t("REPORTS.ANNOTATION"), key: "annotation" },
      ],

      showOverlay: true,
      selectedTeamId: null,

      selectedPart: null,
      newPart: {
        devices: [],
        parameters: [],
        messageTypeIds: [],
        reportPartTypeId: Application.constants.REPORT_PART_TYPES.MEASURE,
        reportPartTypeName: null,
        groupTypeId: "f5bd9cb2-a046-4612-abdc-307390b4c841",
        groupTypeName: null,
        viewTypeId: "d8df8258-7c42-4de0-8bb1-73cd61832143",
        viewTypeName: null,
        sortOrder: 0,
        annotation: null,
      },

      filterDevices: "",
      selectedDevices: [],

      filterParameters: "",
      selectedParameters: [],

      reportPartTypes: [],
      groupTypes: [],
      viewTypes: [],
      messageTypes: [],

      selectedMessageTypes: [],

      constants: Application.constants,

      reportPartTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.ReportPartType",
        requestType: "GET",
        requestParams: {},
      },
      groupTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.GroupType",
        requestType: "GET",
        requestParams: {},
      },
      viewTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.ViewType",
        requestType: "GET",
        requestParams: {},
      },

      messageTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.MessageType",
        requestType: "GET",
        requestParams: {},
      },

      createNewStr: this.$t("REPORTS.CREATE_NEW_REPORT"),
      editStr: this.$t("REPORTS.EDIT_REPORT"),

      partMap: {},
      viewMap: {},
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.REPORTS"), route: "/reports" },
      { title: title },
    ]);
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        this.dto.global == true
      );
    },
  },

  created: async function () {
    this.loadReportPartTypes();
    this.loadViewTypes();

    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/report/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => {
          //                        this.selectedTeamId = response.teamId;
          this.dto = response;
        })
        .catch(this.onError);
    }

    this.showOverlay = false;
    //            if (this.dto.id != null) {
    //                this.$refs.teamTypeahead.$data.inputValue = this.dto.teamName;
    //            }
    this.loadMessageTypes();
    this.loadGroupTypes();
  },

  methods: {
    onTeamSelected: function (value) {
      this.selectedTeamId = value.id;
    },

    loadReportPartTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.reportPartTypesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.partMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.reportPartTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    loadGroupTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.groupTypesRequest)
        .then((response) => {
          for (const value of response) {
            this.groupTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    loadViewTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.viewTypesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.viewMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.viewTypes.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    loadMessageTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.messageTypesRequest)
        .then((response) => {
          for (const value of response) {
            this.messageTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onSaveClicked: function () {
      for (let i = 0; i < this.dto.reportParts.length; i++) {
        let p = this.dto.reportParts[i];
        if (
          p.reportPartTypeId == Application.constants.REPORT_PART_TYPES.MESSAGE
        ) {
          p.groupTypeId = "f5bd9cb2-a046-4612-abdc-307390b4c841"; //none
          p.viewTypeId = "d8df8258-7c42-4de0-8bb1-73cd61832143"; //table
          p.parameters.splice(0, p.parameters.length);
        }
      }

      this.errors = [];
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    tabChanged: async function () {
      let that = this;
      this.$store.dispatch(SET_ACTIONS, [
        {
          label: this.$t("ACTIONS.ADD"),
          action: this.onCreateNewPartAction,
          icon: "fas fa-plus",
          disabled: function () {
            return that.readOnly;
          },
        },
        {
          label: this.$t("ACTIONS.EDIT"),
          action: this.onEditPartAction,
          disabled: function () {
            return (
              that.selectedPart === null || that.showOverlay || that.readOnly
            );
          },
          icon: "fas fa-edit",
        },
        {
          label: this.$t("ACTIONS.DELETE"),
          action: this.onDeletePartAction,
          disabled: function () {
            return (
              that.selectedPart === null || that.showOverlay || that.readOnly
            );
          },
          icon: "fas fa-trash-alt",
        },
      ]);
    },

    onCreateNewPartAction: function () {
      this.$bvModal.show("modal-add-part");
    },

    onEditPartAction: function () {
      this.newPart = this.selectedPart;
      for (const device of this.newPart.devices) {
        this.selectedDevices.push(device);
      }
      for (const parameter of this.newPart.parameters) {
        this.selectedParameters.push(parameter);
      }

      for (const messageType of this.newPart.messageTypeIds) {
        this.selectedMessageTypes.push(messageType);
      }

      this.$bvModal.show("modal-add-part");
    },

    onDeletePartAction: function () {
      for (let i = 0; i < this.dto.reportParts.length; i++) {
        if (this.dto.reportParts[i].id == this.selectedPart.id) {
          this.dto.reportParts.splice(i, 1);
        }
      }
    },

    addNewPart: function () {
      for (const type of this.reportPartTypes) {
        if (type.value == this.newPart.reportPartTypeId)
          this.newPart.reportPartTypeName = type.text;
      }
      for (const type of this.groupTypes) {
        if (type.value == this.newPart.groupTypeId)
          this.newPart.groupTypeName = type.text;
      }
      for (const type of this.viewTypes) {
        if (type.value == this.newPart.viewTypeId)
          this.newPart.viewTypeName = type.text;
      }
      this.newPart.devices.splice(0, this.newPart.devices.length);
      this.newPart.parameters.splice(0, this.newPart.parameters.length);
      this.newPart.messageTypeIds.splice(0, this.newPart.messageTypeIds.length);

      for (const device of this.selectedDevices) {
        this.newPart.devices.push(device);
      }
      for (const parameter of this.selectedParameters) {
        this.newPart.parameters.push(parameter);
      }
      for (const messageType of this.selectedMessageTypes) {
        this.newPart.messageTypeIds.push(messageType);
      }

      if (this.newPart.id) {
        // edit part
        for (let i = 0; i < this.dto.reportParts.length; i++) {
          if (this.newPart.id == this.dto.reportParts[i].id) {
            this.dto.reportParts[i] = this.newPart;
          }
        }
      } else {
        // add part
        this.dto.reportParts.push(this.newPart);
      }

      this.newPart = {
        devices: [],
        parameters: [],
        messageTypeIds: [],
        reportPartTypeId: Application.constants.REPORT_PART_TYPES.MEASURE,
        reportPartTypeName: null,
        groupTypeId: "f5bd9cb2-a046-4612-abdc-307390b4c841",
        groupTypeName: null,
        viewTypeId: "d8df8258-7c42-4de0-8bb1-73cd61832143",
        viewTypeName: null,
        sortOrder: this.dto.reportParts.length,
        annotation: null,
      };

      this.selectedDevices.splice(0, this.selectedDevices.length);
      this.selectedParameters.splice(0, this.selectedParameters.length);
      this.selectedMessageTypes.splice(0, this.selectedMessageTypes.length);
    },

    cancelPart: function () {
      this.newPart = this.newPart = {
        devices: [],
        parameters: [],
        messageTypeIds: [],
        reportPartTypeId: null,
        reportPartTypeName: null,
        groupTypeId: null,
        groupTypeName: null,
        viewTypeId: null,
        viewTypeName: null,
      };
      this.selectedDevices.splice(0, this.selectedDevices.length);
      this.selectedParameters.splice(0, this.selectedParameters.length);
      this.selectedMessageTypes.splice(0, this.selectedMessageTypes.length);
    },

    onDeviceSelected(data) {
      //@ts-ignore
      this.selectedDevices.push(data);
    },

    removeDevice(index, data) {
      this.selectedDevices.splice(index, 1);
      this.newPart.devices.splice(index, 1);
    },

    onParameterSelected(data) {
      //@ts-ignore
      this.selectedParameters.push(data);
    },

    removeParameter(index, data) {
      this.selectedParameters.splice(index, 1);
      this.newPart.parameters.splice(index, 1);
    },

    onPartRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedPart = items[0];
      } else {
        this.selectedPart = null;
      }
    },

    partFormatter: function (value) {
      if (value && value in this.partMap)
        return this.partMap[value].nameLocalized;

      return "";
    },
    viewFormatter: function (value) {
      if (value && value in this.viewMap)
        return this.viewMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
